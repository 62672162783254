import React from "react";
import Layout from "../../components/layouts/layout";
import Seo from "@/components/Seo";

const Privacy = () => {
    return (
        <Layout>
            <section id="privacy" className="mt-[120px]">
                <div className="privacy ">
                    <div className="privacy--title text-[30px] mb-[70px] text-center font-bold "><h2>利用規約</h2></div>
                    <div className="privacy__content text-[15px]">
                        <p className="mb-[12.5px]">本利用規約（以下「本規約」といいます。）は、株式会社ＪＯＡホールディング（以下「当社」といいます。）が提供する本サービス（第２条②にて定義のとおり。以下同じ）の提供条件及び当社とユーザーとの間の権利関係が定めています。 <br/>本サービスのご利用に際しては本規約の全文をお読みいただいた上で、本規約に同意頂く必要があります。なお、ユーザーが本サービスの利用を開始した時点をもって本規約に同意をしたものとみなします。また、本規約は予告なしに変更されることがありますので、適宜最新の利用規約を確認してください。</p>
                        <div className="mt-[12.5px]">
                            <span>第１条（はじめに） </span><br/>
                            <ol>
                                <li className="pl-[25px]">当社は、本サービスを提供するにあたり、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）第２条第１項に定める「個人情報」及びプライバシー情報の保護を当社の責務として認識し、個人情報保護法その他の法律、規則、条例及びガイドライン等（以下「法令等」といいます。）の遵守に努めております。</li>
                                <li className="pl-[25px]">営利目的であるか否かを問わず、また有償か無償かを問わず、第三者に対し当社ＤＢ等及びユーザー自身において当社ＤＢ等を編集・加工・スクレイピングするなどした派生物を譲渡、頒布、貸与、公衆送信することを固く禁じます。</li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第２条（定義）</span> <br/>
                            <span className="ml-[7.5px]">
                            本規約において使用する用語の定義は、次の通りとします。 <br/>
                            ①　本システム　当社が提供する公的支援情報検索システム（名称：８LIFE） <br/>
                            ②　利　用　者　本サービスの利用契約を締結した個人または法人  <br/>
                            ③　利用者ＩＤ　利用者を識別するために当社が発行する固有の識別符号  <br/>
                            ④　パスワード　利用者ＩＤとの組合せにより利用者を識別するために設定される数字または字の組合せ  <br/>
                            ⑤　専用ＵＲＬ　本システムを利用するために利用者毎に発行される専用のＵＲＬ  <br/>
                            </span>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第３条（適用） </span><br/>
                            <ol className="ml-[7.5px]">
                                <li className="pl-[25px]">本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に係わる一切の関係に適用されます。</li>
                                <li className="pl-[25px]">当社が当社サイト上で掲載する本サービスの利用に関する条件は、本規約の一部を構成するものとします。</li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第４条（サービス利用料）</span><br/>
                            <ol>
                                <li className="pl-[25px]">本サービスの利用料金は当社サイトに掲示する料金表に定めるとおりとします。なお、当社は、当社サイト上の料金表を当社の判断に基づき変更することができるものとします。</li>
                                <li className="pl-[25px]">追加ＩＤ、追加企業登録数は別途費用が発生するものとします。</li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第４条（サービス利用料）</span><br/>
                            <ol>
                                <li className="pl-[25px]">本サービスの利用料金は当社サイトに掲示する料金表に定めるとおりとします。なお、当社は、当社サイト上の料金表を当社の判断に基づき変更することができるものとします。  </li>
                                <li className="pl-[25px]">追加ＩＤ、追加企業登録数は別途費用が発生するものとします。 </li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第６条（本システムの利用許諾）</span><br/>
                            <ol>
                                <li className="pl-[25px]">当社は、ユーザーに対し、所定の初期設定費用及び月額利用料金が支払われることを 条件に、本システムを非独占的に使用する権利を許諾します。  </li>
                                <li className="pl-[25px]">当社及びユーザーは、本契約がサービス利用契約であり、本システムの著作権、所有権その他あらゆる権利の譲渡を伴う契約ではないことを相互に確認します。 </li>
                                <li className="pl-[25px]">ユーザーは、本システムを利用するために必要な機器、ソフトウェア、通信回線等を自らの責任と費用にて整備しなければなりません。本システムを利用する際の推奨環境は、当社より専用ＵＲＬにて別途掲載します。</li>
                                <li className="pl-[25px]">当社は本システム内に設置してある広告枠にて自由に掲載出来るものとし、ユーザー はこれを承認するものとします。</li>
                                <li className="pl-[25px]">ユーザーは、コンピュータウィルスの感染防止等のセキュリティ対策を自己の責任と 費用にて講じるものとします。</li>
                                <li className="pl-[25px]">本サービスの利用可能地域は、日本国内に限るものとします。</li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第７条（登録情報の変更） </span><br/>
                            <p>ユーザーは、登録情報に変更が発生した場合は、速やかに変更の手続を行わなければならず、これを怠ったことによりユーザーに不利益や不具合が発生しても、当社は一切の責任を負わないものとします。 </p>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第８条（禁止行為）  </span>
                            <ol>
                                <li className="pl-[25px]">
                                    ユーザーは、本サービスの利用に際し、次の各号に掲げる行為を行ってはならないものとします。
                                    <span>
                                    ①　複数人で1つの利用者ＩＤを共同して保有する行為 <br/>
                                    ②　第三者に本サービスを利用させる行為 <br/>
                                    ③　利用者ＩＤを第三者に譲渡、質入、貸与する行為 <br/>
                                    ④　利用者ＩＤを停止された者に代わって利用者ＩＤを取得する行為 <br/>
                                    ⑤　本サービスの利用により得た各種公的支援情報を再販売、二次利用（有償・無償を問いません）する行為 <br/>
                                    ⑥　本サービスで得た情報をインターネット上で入手できる状態にする行為<br/>
                                    ⑦　本サービスの内容や本サービスにより利用しうる情報を改ざんまたは消去する行為 ⑧　本サービスの運営及び本システムに支障を与えるなど本サービスの円滑な運営を妨げる行為またはそのおそれのある行為 <br/>
                                    ⑨　ウィルス等の有害なコンピュータプログラム等を送信する行為 <br/>
                                    ⑩　上記の他、当社が不適切と判断する行為 <br/>
                                    </span>
                                </li>
                                <li className="pl-[25px]">前項の利用制限に関して発生した損害について、当社は、一切の責任を負わないものとします。  </li>
                                <li className="pl-[25px]">当社は、ユーザーが前項各号のいずれかに該当した場合またはユーザーが月額利用料金の支払を怠った場合、ユーザーに事前に通知することなく本サービスの利用の全部または一部を制限することができます。 </li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第９条（非保証・免責）</span>
                            <ol>
                                <li className="pl-[25px]">
                                    当社は、次の各号に掲げる事項ついて一切の保証を行わないものとします。
                                    <span>
                                    ①　本サービスは、国内すべての公的支援情報を検索するものではなく、また、ユーザーによる公的支援の受給を保証するものでもないこと。<br/>
                                    ②　本サービスを通じて提供される公的支援情報の正確性及び本システムの完全性。<br/>
                                    ③　本サービス利用に起因して利用者のＰＣに不具合や障害が生じないこと。<br/>
                                    ④　本サービスが永続すること。<br/>
                                    ⑤　本サービスの利用に中断またはエラーが発生しないこと。<br/>
                                    </span>
                                </li>
                                <li className="pl-[25px]">
                                    当社は、次の各号に掲げる事由について一切の責任を負わないものとします。
                                    <span>
                                    ①　閲覧するＰＣ利用環境に起因する一切の不具合。<br/>
                                    ②　本サービスの内容変更、中断、終了によって生じた損害。<br/>
                                    ③　予期せぬ要因で専用ＵＲＬの閲覧に関して生じた障害。<br/>
                                    ④　専用ＵＲＬからリンクしているウェブサイトの合法性、信頼性、正確性。<br/>
                                    ⑤　未知のコンピュータウィルスによって生じた損害。<br/>
                                    ⑥　通常の注意をもってしても防御できない不正アクセスまたは通信経路上での傍受。<br/>
                                    ⑦　第三者の製造するハードウェア、ソフトウェアによって生じた損害。<br/>
                                    ⑧　電気通信事業者の提供するサービスの不具合によって生じた損害。<br/>
                                    ⑨　本サービスを利用することによりユーザーと第三者との間で生じた紛争及び損害。<br/>
                                    </span>
                                </li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１０条（一時的なサービス中断及び提供停止） </span>
                            <ol>
                                <li className="pl-[25px]">
                                    当社は、次の各号のいずれかに該当する場合には、ユーザーへ事前の連絡をすることなく、本サービスの提供を中断することができるものとします。<br/>
                                    <span>
                                    ①　本サービスに必要な設備の保守作業を行う場合。<br/>
                                    ②　本サービス運用上の理由で、やむを得ない場合。<br/>
                                    ③　不可抗力により、本サービスを提供できない場合。<br/>
                                    </span>
                                </li>
                                <li className="pl-[25px]">
                                    当社は、前各項に定める事由によりユーザーに対し本サービスを提供できなかったことに関して、ユーザーまたは第三者に損害が発生しても、一切責任を負わないものとし ます。
                                </li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１１条（秘密保持義務） </span>
                            <ol>
                                <li className="pl-[25px]">
                                    ユーザーは、本契約に基づき当社から開示された本サービスに関する技術上の情報（以下「秘密情報」といいます。）についての秘密を保持し、当社の事前の書面による承諾なしに 第三者に開示、漏洩せず、また開示目的以外に使用してはなりません。 
                                </li>
                                <li className="pl-[25px]">
                                ユーザーは、当社の書面による事前の承諾を得て秘密情報を第三者又は業務上秘密情 報を知る必要のある役員及び従業員に開示する場合には、当該第三者、役員及び従業員 に対して、本契約と同様の秘密保持義務を負わせなければならない。
                                </li>
                                <li className="pl-[25px]">
                                前２項にかかわらず、次の各号の一に該当する情報について、ユーザーは秘密保持義 務を負わないものとします。 
                                <span>
                                    ①　開示の時点で公知の情報 <br/>
                                    ②　ユーザーが当該情報の受領時に既知であったことを証明できる情報<br/>
                                    ③　ユーザーが正当な開示権限を有する第三者から正当に入手した情報<br/>
                                    ④　開示後にユーザーの責めによらずして公知となった情報 <br/>
                                </span>
                                </li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１１条（秘密保持義務） </span>
                            <ol>
                                <li className="pl-[25px]">ユーザーは、本契約に基づき当社から開示された本サービスに関する技術上の情報（以下「秘密情報」といいます。）についての秘密を保持し、当社の事前の書面による承諾なしに 第三者に開示、漏洩せず、また開示目的以外に使用してはなりません。 </li>
                                <li className="pl-[25px]">ユーザーは、当社の書面による事前の承諾を得て秘密情報を第三者又は業務上秘密情 報を知る必要のある役員及び従業員に開示する場合には、当該第三者、役員及び従業員 に対して、本契約と同様の秘密保持義務を負わせなければならない。</li>
                                <li className="pl-[25px]">
                                    前２項にかかわらず、次の各号の一に該当する情報について、ユーザーは秘密保持義 務を負わないものとします
                                    <span>
                                    ①　開示の時点で公知の情報 <br/>
                                    ②　ユーザーが当該情報の受領時に既知であったことを証明できる情報<br/>
                                    ③　ユーザーが正当な開示権限を有する第三者から正当に入手した情報<br/>
                                    ④　開示後にユーザーの責めによらずして公知となった情報 <br/>
                                    </span>
                                </li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１２条（競業避止等）  </span>
                            ユーザーは、本契約期間中及び本契約終了後３年間は、当社の書面による事前の承諾なくして、本サービスや本システムと同一または類似すると甲が判断するサービス、システム、商材を、法人個人を問わず販売、提供、もしくは取次等の事業をしてはならないものとします。
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１３条（期限の利益の喪失） </span>
                            当社及びユーザーは、相手方に次の各号のいずれか一つに該当する事由が生じたときは、本契約上の債務について直ちに期限の利益を喪失するとともに、直ちに本契約を解除する ことができるものとします。<br/>
                            <span>
                            ①　本契約に定める義務の履行を怠ったとき<br/>
                            ②　第８条に定める禁止行為を行ったとき<br/>
                            ③　差押え、仮差押え、仮処分、租税滞納処分、その他公権力の処分を受け、または会社更生手続及び民事再生手続の開始、破産もしくは競売を申し立てられ、または自ら会 社更生手続、民事再生手続の開始もしくは破産申立てをしたときまたは第三者からこれらの申立てがなされたとき<br/>
                            ④　資本減少、営業の廃止もしくは変更、または解散の決議をしたとき<br/>
                            ⑤　公租公課の滞納処分を受けたとき<br/>
                            ⑥　その他前３号に準ずる信用の悪化と認められる事実が発生したとき <br/>
                            </span>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１４条（反社会的勢力の排除）  </span>
                            <ol>
                                <li className="pl-[25px]">
                                    当社及びユーザーは、互いに相手方に対し、次の各号の事項を表明し、保証します。
                                    <span>
                                    ①　自ら、自らの役員・使用人・従業員等、親会社、子会社、又は関連会社（以下総称して「対象者」といいます。）が、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下総称して「反社会的勢力」といいます。）のいずれにも該当しないこと。<br/>
                                    ②　反社会的勢力に自己の名義を利用させ、本契約を締結するものではないこと。<br/>
                                    ③　自ら又は第三者を利用した、詐術、暴力的行為、脅迫的言辞又は法的な責任を超えた 不当な要求等の行為。<br/>
                                    ④　相手方に対する業務妨害にあたる行為。<br/>
                                    ⑤　反社会的勢力から名目の如何を問わず、資本・資金の導入及び関係を構築する行為。<br/>
                                    ⑥　反社会的勢力に対して名目の如何を問わず、資金提供をする行為。<br/>
                                    ⑦　反社会的勢力が甲及び乙の経営に関与する行為。<br/>
                                    </span>
                                </li>
                                <li className="pl-[25px]">当社及びユーザーは、相手方が前項に違反していると合理的に判断したときは、違反 した相手方に対し、何らの催告もなく、両者間の取引にかかる全ての契約を解除することができ、相手方はこれに対し何ら異議を申し立てないものとします。</li>
                                <li className="pl-[25px]">当社及びユーザーは、前項により解除された者が損害を被ったとしても、これを一切 賠償する義務を負わないものとします。</li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１５条（契約期間）</span>
                            <ol>
                                <li className="pl-[25px]">本サービスの契約期間は当社サイトに掲示する料金表に定めるとおりとします。なお、当社は、当社サイト上の料金表を当社の判断に基づき変更することができるものとします</li>
                                <li className="pl-[25px]">前項の有効期間満了の１日前までに双方から本契約を更新しない旨の意思表示が相手 方に到達しない場合、本契約は、同一条件にて１か月間更新されるものとし、以後も同 様とします。 </li>
                            </ol>
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１６条（途中解約）</span>
                            ユーザーは、契約期間満了前に、正当な理由がない限り、途中解約が出来ません。ただ し、契約期間分の残額利用料を支払うことによって途中解約が出来るものとします。
                        
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１７条（損害賠償） </span>
                            当社及びユーザーは、本規約に違反して相手方に損害を与えたとき、その損害を賠償するものとします。ただし、当社の賠償額は、ユーザーが当社に支払った利用料金の額を上限とします。
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１８条（遅延損害金）  </span>
                            ユーザーは利用料金の支払を遅延した場合、当社に対し支払期日の翌日から解決の日まで年１４．６％の遅延損害金を支払うものとします。
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第１９条（存続条項）   </span>
                            本契約が終了した後も、第１１条（秘密保持義務）、第１２条（競業避止等）、第１４条（反社会的勢力の排除）、第１７条（損害賠償）、第１８条（遅延損害金）、第２１条（合意管轄）は有効に存続するものとします。
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第２０条（協議）  </span>
                            本規約に定めのない事項が生じたとき、又は本規約の各条項の解釈に関し疑義が生じたときには、誠意をもって協議し、これを解決するものとします。
                        </div>
                        <div className="mt-[12.5px]">
                            <span>第２１条（合意管轄） </span>
                            本契約につき裁判上の争いとなったときは、当社の本店所在地を管轄する裁判所を第一審の合意管轄裁判所とします。
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
 
export default Privacy;

export const Head = () => (
    <Seo
        title="Privacy | 8LIFE"
    />
);